
import { v4 as uuid } from 'uuid'
import type { IAppState, INotify } from '$lib/types'
import { writable } from 'svelte/store'
import { get } from 'svelte/store'

const appState: IAppState = {
	theme: 'light',
	appLayout: {
		currentPageTitle: 'Login',
		sidebarVisible: false
	},
	notify: []
}
const store = writable(appState)
const { subscribe, update, set } = store



const toggleTheme = () => {
	update((state) => {
		state.theme = state.theme === 'light' ? 'dark' : 'light'
		return state
	})
}
const setPageTitle = (pageTitle: string) => {
	update((state) => {
		state.appLayout.currentPageTitle = pageTitle
		return state
	})
}
const createToast = (messageType: string, message: string) => {
	const newNotice: INotify = {
		id: uuid(), message: message, title: messageType, canDismiss: true, timer: 10000, visible: true
	}
	let index: number = -1
	let totalVisible: number = -1
	update((state) => {
		index = state.notify.length
		totalVisible = state.notify.filter((toast) => {
			return toast.visible
		}).length + 1
		const newState = {
			...state,
			notify: [
				...state.notify, newNotice
			]
		}
		return newState
	})
	console.log(`totalVisible:${totalVisible}`)
	console.log(`index: ${index}`)
	if (index > -1 && totalVisible > 0)
		setTimeout(() => {
			destroyToast(index)
		}, 2000 * totalVisible)
}
const destroyToast = (index: number) => {

	update((state) => {
		const itemsInArray = state.notify?.length
		const newState = {
			...state,
			notify: itemsInArray === 1 ? [] : [
				...state.notify.slice(0, index), ...state.notify.slice(index + 1)
			]
		}
		return newState
	})

}

const dismissToast = (appAlert: INotify) => {
	const { notify } = get(store)
	const indexOfToDelete = notify.findIndex((x) => x.id === appAlert.id)
	if (indexOfToDelete !== -1)
		destroyToast(indexOfToDelete)
}

const toggleSidebar = (isOpen: boolean) => {
	update((state) => {
		const newState = {
			...state,
			appLayout: {
				...state.appLayout, sidebarVisible: isOpen
			}
		}
		return newState
	})
}
export const appStore = {
	subscribe,
	update,
	toggleTheme,
	setPageTitle,
	destroyToast,
	createToast,
	dismissToast,
	toggleSidebar
}
